import Globe from "globe.gl";

const markerSvg = `<a href="projects"><svg class="hover:text-orange" viewBox="-4 0 36 36">
<path fill="currentColor" d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"></path>
<circle fill="black" cx="14" cy="14" r="7"></circle>
</svg></a>`;

export default function GlobeContainer(el) {
  const myGlobe = Globe();
  const inner = el.querySelector("[data-inner]");
  const locations = JSON.parse(el.dataset.locations).map((l) => ({
    lat: +l.lat,
    lng: +l.lng,
    size: 30,
    color: "white",
  }));

  let rect = el.getBoundingClientRect();
  let w = rect.width;
  let h = rect.height;

  console.log(locations);

  myGlobe(inner)
    .globeImageUrl("/images/earth-blue-marble.jpg")
    .backgroundColor("#FFFFFF00")
    .width(w)
    .height(h)
    .htmlElementsData(locations)
    .htmlElement((d) => {
      const el = document.createElement("div");
      el.innerHTML = markerSvg;
      el.style.color = d.color;
      el.style.width = `${d.size}px`;

      el.style["pointer-events"] = "auto";
      el.style.cursor = "pointer";
      el.onclick = () => console.info(d);
      return el;
    });

  const controls = myGlobe.controls();
  controls.enableZoom = false;
  controls.enableRotate = true;
  controls.autoRotate = true;
  controls.autoRotateSpeed = 2.5;
  // controls.minDistance = 0;

  window.addEventListener("resize", () => {
    rect = el.getBoundingClientRect();
    w = rect.width;
    h = rect.height;
    myGlobe.width(w).height(h);
  });
}
